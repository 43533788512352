/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

const Rails = require("@rails/ujs")
Rails.start()
window.Rails = Rails

const TurboLinks = require("turbolinks")
TurboLinks.start()

const littlefoot = require('littlefoot/dist/littlefoot')
import Zooming from 'zooming'

// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

var lf = null

Turbolinks.scroll = {}

document.addEventListener("turbolinks:load", function() {
  // keep scroll position for certain turbolinks links & form submits
  const elements = document.querySelectorAll("[data-turbolinks-scroll]");
  elements.forEach(function(element){
    element.addEventListener("click", ()=> {
      Turbolinks.scroll['top'] = document.scrollingElement.scrollTop;
    });

    element.addEventListener("submit", ()=> {
      Turbolinks.scroll['top'] = document.scrollingElement.scrollTop;
    });
  });

  if (Turbolinks.scroll['top']) {
    document.scrollingElement.scrollTo(0, Turbolinks.scroll['top']);
  }

  Turbolinks.scroll = {};

  lf = littlefoot.default()

  // jquery stuff
  $('#js-menu-button').on('click', function(e) {
    $(this).toggleClass('is-active');
    $('#navigation').toggleClass('is-active')

    if ($(this).attr('aria-expanded') == 'false') {
      $(this).attr('aria-expanded', 'true')
    } else {
      $(this).attr('aria-expanded', 'false')
    }
  })

  // close quick feedback on escape
  $('#js-quick-feedback-form textarea').keyup(function(e) {
    // escape
    if (e.keyCode == 27) {
      toggleQuickFeedbackForm()
    }
  })

  // show chapter note form
  $('#js-chapter-note-btn').on('click', function(e) {
    e.preventDefault()
    window.isEditingChapterNotes = true

    $('#js-chapter-note-form').removeClass('is-hidden')
    $('#js-chapter-note-form textarea').focus()
    $('#js-chapter-note-md').removeClass('is-hidden')
    $('#js-chapter-note-save-btn').removeClass('is-hidden')
    autosize.update($('#js-chapter-note-form textarea')[0])
    $(this).hide()
  })

  $('#js-chapter-note-edit-btn').on('click', function(e) {
    e.preventDefault()
    window.isEditingChapterNotes = true

    $('#js-chapter-note-form').removeClass('is-hidden')

    // focus textarea with cursor at the end
    const $textarea = $('#js-chapter-note-form textarea')
    $textarea.focus()
    $textarea[0].setSelectionRange($textarea[0].value.length,$textarea[0].value.length);

    $('#js-chapter-note-content').addClass('is-hidden')
    $('#js-chapter-note-md').removeClass('is-hidden')
    $('#js-chapter-note-save-btn').removeClass('is-hidden')
    autosize.update($textarea[0])
    $(this).hide() // hide edit button to make room for save button
  })

  $('#js-chapter-note-save-btn').on('click', function(e) {
    e.preventDefault()
    window.isEditingChapterNotes = false
    Rails.fire($('#js-chapter-note-form')[0], 'submit')
  })

  autosize($('textarea[autosize]'))

  // open & close dropdowns
  $('.dropdown-trigger').each(function(_, el) {
    $(el).on('click', function(e) {
      var wasActive = false
      if ($(el).parent().hasClass('is-active')) {
        wasActive = true
      }

      closeDropdowns()
      e.stopPropagation()

      if (!wasActive) {
        $(this).parent().addClass('is-active')
        $(this).find('i.fa-angle-down').removeClass('fa-angle-down').addClass('fa-angle-up')
      }
    })
  })

  // but not close them when clicking on non-link items inside
  $('div.dropdown-item').each(function(_, el) {
    $(el).on('click', function(e) {
      e.stopPropagation()
    })
  })

  $('#js-toggle-course-description').on('click', function(e) {
    $(this).find('.is-open, .is-closed').toggleClass('is-hidden')
    $('#js-course-description').toggleClass('is-hidden-touch')
  })

  $('#js-toggle-course-nav-mobile').on('click', function(e) {
    // switch the caret icon
    $(this).find('div').toggleClass('is-hidden')

    // show dropdowns and
    $('.js-nav-dropdown').toggleClass('is-hidden-mobile')
    $('.js-complete-chapter').toggleClass('is-hidden-mobile')
  })

  $('.js-toggle-quick-feedback').on('click', () => toggleQuickFeedbackForm())


  // start image zooming
  new Zooming({
    enableGrab: false,
    customSize: '130%',
    transitionDuration: 0.2,
  }).listen('.content img')
})

// prevent navigation if chapter notes are being edited to prevent potential data loss
document.addEventListener('turbolinks:before-visit', function(e) {
  if (window.isEditingChapterNotes) {
    e.preventDefault()
    alert('Please save the chapter notes before continuing.')
  }
})

document.addEventListener('click', function (event) {
  closeDropdowns()
})

function closeDropdowns() {
  $('.dropdown').each(function (_, el) {
    $(el).removeClass('is-active')
    $(el).find('i.fa-angle-up').removeClass('fa-angle-up').addClass('fa-angle-down')
  })
}

function toggleQuickFeedbackForm() {
  $('#js-quick-feedback-form').toggleClass('is-open')
  $('#js-quick-feedback-form textarea')[0].focus()
  return false
}

function quickFeedbackSubmitted() {
  $('#js-quick-feedback-form .success').show()
  $('#js-quick-feedback-form .actions').hide()
  $('#js-quick-feedback-form .quick-feedback-inner').addClass('bounceOutUp delay-2s')

  setTimeout(function(){
    $('#js-quick-feedback-form').removeClass('is-open')
    $('#js-quick-feedback-form .quick-feedback-inner').removeClass('bounceOutUp delay-2s')
    $('#js-quick-feedback-form textarea')[0].value = ''
    $('#js-quick-feedback-form .success').hide()
    $('#js-quick-feedback-form .actions').show()
  }, 3000)
}

// this is called as a result of a successful
function resentConfirmationEmail() {
  $('#js-confirm-email-banner .button').hide()
  $('#js-confirm-email-banner .status').show()
}

function toggleHidden(id) {
  $('#'+id).toggleClass('is-hidden')
}

function showQuestion(id) {
  // hide all questions
  $('.question').addClass('is-hidden')

  // show the target one
  $('#'+id).removeClass('is-hidden')
}

document.addEventListener("turbolinks:before-cache", function() {
  // unmount littlefoot footnotes
  lf.unmount()

  // remove flash to prevent it from flickering and animating again when using back/forward
  $('.flash').remove()

  closeDropdowns()
})

// d i r t y h a c k s
window.quickFeedbackSubmitted = quickFeedbackSubmitted
window.resentConfirmationEmail = resentConfirmationEmail
window.toggleHidden = toggleHidden
window.showQuestion = showQuestion

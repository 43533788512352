import React, { useState } from 'react'

// copied 1:1 from https://josephkhan.me/javascript-copy-clipboard-safari/
const copyToClipboard = str => {
  let textArea

  function isOS() {
    // can use a better detection logic here
    return navigator.userAgent.match(/ipad|iphone/i)
  }

  function createTextArea(text) {
    textArea = document.createElement('textArea')
    textArea.readOnly = true
    textArea.contentEditable = true
    textArea.value = text
    document.body.appendChild(textArea)
  }

  function selectText() {
    let range, selection

    if (isOS()) {
      range = document.createRange()
      range.selectNodeContents(textArea)
      selection = window.getSelection()
      selection.removeAllRanges()
      selection.addRange(range)
      textArea.setSelectionRange(0, 999999)
    } else {
      textArea.select()
    }
  }

  function copyTo() {
    document.execCommand('copy')
    document.body.removeChild(textArea)
  }

  createTextArea(str)
  selectText()
  copyTo()
}

const onClick = (e, str, setIcon) => {
  e.preventDefault()
  copyToClipboard(str)
  setIcon('check')

  // reset icon after short delay
  setTimeout(() => setIcon('copy'), 2500)
}

// renders a button that when clicked copies `copyable` to the user’s clipboard,
// then shows 'Copied!' as the button text with an updated icon
export default ({ className, text, copyable }) => {
  const [icon, setIcon] = useState('copy')
  const isCopied = icon === 'check'

  return <button
    className={className}
    onClick={(e) => onClick(e, copyable, setIcon)} >
    <span className='icon'><i className={`fal fa-${icon}`}/></span>
    <span>{text}</span>
  </button>
}

import React, { useState, useRef } from 'react'
import CirclePicker from 'react-color/lib/Circle'

export default ({ initials, color, colors }) => {
  const [c, setColor] = useState(color)
  const formEl = useRef(null)

  const onClick = (color, setColor) => {
    setColor(color.hex)
    window.Rails.fire(formEl.current, 'submit')
  }

  return (
    <>
      <form ref={formEl} action='/account' data-remote='true' method='post' data-turbolinks-scroll='false'>
        <input type='hidden' name='_method' value='patch' />
        <input type='hidden' value={c} name="user[color]" />
      </form>

      <div className='field'>
        <label className='label'>Avatar</label>
        <div className='control'>
          <div className='columns'>
            <div className='column is-narrow'>
              <div className='user-avatar'>
                <div className='avatar is-large' style={{backgroundColor: c}}><span className='initials'>{initials}</span></div>
              </div>
            </div>

            <div className='column'>
              <CirclePicker
                color={c}
                colors={colors}
                onChangeComplete={(color) => onClick(color, setColor)}
                circleSize={20}
                circleSpacing={12}
               />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
